<template>
  <div>
    <div v-if="element" class="sidebar-right-container position-relative">
      <zem-card class="tw-flex tw-items-start tw-justify-between">
        <div>
          <zem-card-title v-if="$route.query.type === 'pipeline'">{{
            element.name
          }}</zem-card-title>
          <zem-card-title v-else
            >{{ element.pipeline.data.name }} -
            {{ element.name }}</zem-card-title
          >
          <div class="block-id">
            <span class="block-id__label"></span>
            ID {{ element["number"] }}
          </div>
          <div v-if="!closeAcc([3])" class="block-id">
            <span class="block-id__label second"></span>
            ID {{ element["id"] }}
          </div>
        </div>
        <div class="tw-flex tw-items-center tw-gap-x-2">
          <zem-link
            v-if="changeSettings"
            class="tw-hidden md:tw-block"
            @click="closeRightSidebar"
            >Отменить</zem-link
          >
          <zem-button
            v-if="
              ($route.query.type === 'pipeline'
                ? $can('edit', 'order-pipeline-update')
                : $can('edit', 'order-status-update')) && changeSettings
            "
            :loading="editItemLoading"
            class="mobile-d-none"
            @click="$emit('on-save')"
          >
            Сохранить
          </zem-button>
          <img
            :src="require('@/assets/icons/check_primary.svg')"
            alt=""
            class="tw-block md:tw-hidden tw-h-4"
            @click="$emit('on-update')"
          />
          <img
            v-if="!changeSettings"
            :src="require('@/assets/icons/close.svg')"
            alt=""
            class="tw-h-5"
            @click="closeRightSidebar"
          />
        </div>
      </zem-card>

      <zem-card class="tw-mt-3">
        <div class="zem-collapse-table rows">
          <div class="zem-collapse-table__row">
            <div class="zem-collapse-table__column title">
              {{ $t("ordering") }}
            </div>
            <zem-input
              v-model="element.ordering"
              class="mt-0"
              type="number"
              @input="changeSettings = true"
              :disabled="
                $route.query.type === 'pipeline'
                  ? !$can('edit', 'order-pipeline-update')
                  : !$can('edit', 'order-status-update')
              "
            ></zem-input>
          </div>
          <div
            v-if="Object.hasOwn(element, 'amocrm_pipeline_id')"
            class="zem-collapse-table__row"
          >
            <div class="zem-collapse-table__column title">
              ID воронки AmoCRM
            </div>
            <zem-input
              v-model="element['amocrm_pipeline_id']"
              class="mt-0"
              @input="changeSettings = true"
              :disabled="
                $route.query.type === 'pipeline'
                  ? !$can('edit', 'order-pipeline-update')
                  : !$can('edit', 'order-status-update')
              "
            ></zem-input>
          </div>
          <div
            v-if="Object.hasOwn(element, 'amocrm_status_id')"
            class="zem-collapse-table__row"
          >
            <div class="zem-collapse-table__column title">ID этапа AmoCRM</div>
            <zem-input
              v-model="element['amocrm_status_id']"
              class="mt-0"
              @input="changeSettings = true"
              :disabled="
                $route.query.type === 'pipeline'
                  ? !$can('edit', 'order-pipeline-update')
                  : !$can('edit', 'order-status-update')
              "
            ></zem-input>
          </div>
          <div
            v-for="(el, index) in Object.keys(element.params)"
            :key="index"
            class="zem-collapse-table__row"
          >
            <div class="zem-collapse-table__column title">{{ $t(el) }}</div>
            <zem-input
              v-model="element.params[el]"
              class="mt-0"
              @input="changeSettings = true"
              :disabled="
                $route.query.type === 'pipeline'
                  ? !$can('edit', 'order-pipeline-update')
                  : !$can('edit', 'order-status-update')
              "
            ></zem-input>
          </div>
          <template v-for="(el, index) in element['params_schema']">
            <div
              v-if="!Object.keys(element.params).includes(el.key)"
              :key="index"
              class="zem-collapse-table__row"
            >
              <div class="zem-collapse-table__column title">
                {{ el["title"] }}
              </div>
              <zem-input
                v-model="el.value"
                class="mt-0"
                @input="changeSettings = true"
                :disabled="
                  $route.query.type === 'pipeline'
                    ? !$can('edit', 'order-pipeline-update')
                    : !$can('edit', 'order-status-update')
                "
              ></zem-input>
            </div>
          </template>
          <div
            v-if="'show_in_graph' in element"
            class="zem-collapse-table__row"
          >
            <div class="zem-collapse-table__column title">
              Отображать в графике
            </div>
            <zem-checkbox
              v-model="element['show_in_graph']"
              class="mt-0"
              @input="changeSettings = true"
              :disabled="
                $route.query.type === 'pipeline'
                  ? !$can('edit', 'order-pipeline-update')
                  : !$can('edit', 'order-status-update')
              "
            ></zem-checkbox>
          </div>
        </div>
      </zem-card>
    </div>

    <preloader-wrapper :show="$store.state.other.isLoadingItem" />
  </div>
</template>

<script>
import ZemCard from "@/components/ui/ZemCard";
import { closeAcc, logger } from "@/assets/scripts/scripts";
import { options } from "@/mixins/options";
import SidebarRightHeader from "@/components/SidebarRightHeader.vue";
import PreloaderWrapper from "@/components/PreloaderWrapper.vue";
import ZemCollapse from "@/components/ui/ZemCollapse.vue";
import { PlusIcon } from "vue-feather-icons";
import OrderEventsBlock from "@/components/Orders/OrderEventsBlock.vue";
import OrderEstimatesBlock from "@/components/Orders/OrderEstimatesBlock.vue";
import OrdersService from "@/services/orders.service";
import ZemDropdownList from "@/components/ui/ZemDropdownList.vue";
import ZemInput from "@/components/ui/ZemInput.vue";
import OrderPersonsBlock from "@/components/Orders/OrderPersonsBlock.vue";
import ZemCardTitle from "@/components/ui/ZemCardTitle.vue";
import ZemButton from "@/components/ui/ZemButton.vue";
import ZemLink from "@/components/ui/ZemLink.vue";
import ZemMultiSelect from "@/components/ui/ZemMultiSelect.vue";
import ZemRadioButton from "@/components/ui/ZemRadioButton.vue";
import ZemCheckbox from "@/components/ui/Checkbox.vue";

export default {
  emits: ["on-update", "on-edit", "on-close", "on-save"],
  mixins: [options],
  components: {
    ZemCheckbox,
    ZemRadioButton,
    ZemMultiSelect,
    ZemLink,
    ZemButton,
    ZemCardTitle,
    ZemInput,
    ZemDropdownList,
    OrderEstimatesBlock,
    OrderEventsBlock,
    ZemCollapse,
    OrderPersonsBlock,
    PreloaderWrapper,
    SidebarRightHeader,
    ZemCard,
    PlusIcon,
  },
  data() {
    return {
      editElement: true,
      newElement: false,
      elementData: null,
      title: "",
      id: "",
      element: null,
      persons: [],
      performers: [
        {
          id: 0,
          title: "Менеджер",
          data: "Иванов Иван Иванович",
        },
        {
          id: 1,
          title: "Прораб",
          data: "Сидоров Иван Иванович",
        },
        {
          id: 2,
          title: "Снабженец",
          data: "Петров Петр Петрович",
        },
      ],
      events: [
        {
          id: 0,
          title: "Дата создания",
          data: "13.09.2023",
        },
        {
          id: 1,
          title: "Дата завершения",
          data: "25.09.2024",
        },
      ],
      orderStatuses: [],
      currentElementId: null,
      editItemLoading: false,
      changeSettings: false,
    };
  },
  computed: {
    selectedItems() {
      return this.$store.state.orders.selectedItemsForDelete;
    },
  },
  mounted() {
    this.getStage();
  },
  methods: {
    closeAcc,
    logger,
    async getStage() {
      this.$store.commit("other/onChangeLoadingItem", true);
      const { id } = await this.$route.params;
      const { type } = await this.$route.query;
      if (type === "pipeline") {
        OrdersService.getPipeline(id)
          .then((r) => {
            this.element = r.data.data;
            this.$store.commit("other/onChangeLoadingItem", false);
          })
          .catch((e) => {
            if (e.response.status === 422 && this.$route.name !== "orders")
              this.$router.push({ name: "orders" });
            this.closeRightSidebar();
          });
      } else {
        OrdersService.getStage(id)
          .then((r) => {
            this.element = {
              ...r.data.data,
              params: {
                status_letter: "",
                ...r.data.data.params,
              },
            };
            this.$store.commit("other/onChangeLoadingItem", false);
          })
          .catch((e) => {
            if (e.response.status === 422 && this.$route.name !== "orders")
              this.$router.push({ name: "orders" });
            this.closeRightSidebar();
          });
      }
    },
    closeRightSidebar() {
      this.editElement = false;
      this.newElement = false;
      this.$router.push({ name: "orders" });
      this.$emit("on-close");
      this.$store.commit("sidebars/changeRightSidebar", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-right-container {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  -moz-flex-direction: column;
  overflow-y: auto;

  .zem-dropdown {
    margin-top: 0;
  }
}

.colored {
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: $color-accent;

  img {
    margin-right: 3px;
  }

  &--link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 1024px) {
  .sidebar-right-container {
    width: 100%;
    height: calc(100% - 45px);
    overflow: auto;
  }
}
</style>
