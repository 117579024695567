var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.element)?_c('div',{staticClass:"sidebar-right-container position-relative"},[_c('zem-card',{staticClass:"tw-flex tw-items-start tw-justify-between"},[_c('div',[(_vm.$route.query.type === 'pipeline')?_c('zem-card-title',[_vm._v(_vm._s(_vm.element.name))]):_c('zem-card-title',[_vm._v(_vm._s(_vm.element.pipeline.data.name)+" - "+_vm._s(_vm.element.name))]),_c('div',{staticClass:"block-id"},[_c('span',{staticClass:"block-id__label"}),_vm._v(" ID "+_vm._s(_vm.element["number"])+" ")]),(!_vm.closeAcc([3]))?_c('div',{staticClass:"block-id"},[_c('span',{staticClass:"block-id__label second"}),_vm._v(" ID "+_vm._s(_vm.element["id"])+" ")]):_vm._e()],1),_c('div',{staticClass:"tw-flex tw-items-center tw-gap-x-2"},[(_vm.changeSettings)?_c('zem-link',{staticClass:"tw-hidden md:tw-block",on:{"click":_vm.closeRightSidebar}},[_vm._v("Отменить")]):_vm._e(),(
            (_vm.$route.query.type === 'pipeline'
              ? _vm.$can('edit', 'order-pipeline-update')
              : _vm.$can('edit', 'order-status-update')) && _vm.changeSettings
          )?_c('zem-button',{staticClass:"mobile-d-none",attrs:{"loading":_vm.editItemLoading},on:{"click":function($event){return _vm.$emit('on-save')}}},[_vm._v(" Сохранить ")]):_vm._e(),_c('img',{staticClass:"tw-block md:tw-hidden tw-h-4",attrs:{"src":require('@/assets/icons/check_primary.svg'),"alt":""},on:{"click":function($event){return _vm.$emit('on-update')}}}),(!_vm.changeSettings)?_c('img',{staticClass:"tw-h-5",attrs:{"src":require('@/assets/icons/close.svg'),"alt":""},on:{"click":_vm.closeRightSidebar}}):_vm._e()],1)]),_c('zem-card',{staticClass:"tw-mt-3"},[_c('div',{staticClass:"zem-collapse-table rows"},[_c('div',{staticClass:"zem-collapse-table__row"},[_c('div',{staticClass:"zem-collapse-table__column title"},[_vm._v(" "+_vm._s(_vm.$t("ordering"))+" ")]),_c('zem-input',{staticClass:"mt-0",attrs:{"type":"number","disabled":_vm.$route.query.type === 'pipeline'
                ? !_vm.$can('edit', 'order-pipeline-update')
                : !_vm.$can('edit', 'order-status-update')},on:{"input":function($event){_vm.changeSettings = true}},model:{value:(_vm.element.ordering),callback:function ($$v) {_vm.$set(_vm.element, "ordering", $$v)},expression:"element.ordering"}})],1),(Object.hasOwn(_vm.element, 'amocrm_pipeline_id'))?_c('div',{staticClass:"zem-collapse-table__row"},[_c('div',{staticClass:"zem-collapse-table__column title"},[_vm._v(" ID воронки AmoCRM ")]),_c('zem-input',{staticClass:"mt-0",attrs:{"disabled":_vm.$route.query.type === 'pipeline'
                ? !_vm.$can('edit', 'order-pipeline-update')
                : !_vm.$can('edit', 'order-status-update')},on:{"input":function($event){_vm.changeSettings = true}},model:{value:(_vm.element['amocrm_pipeline_id']),callback:function ($$v) {_vm.$set(_vm.element, 'amocrm_pipeline_id', $$v)},expression:"element['amocrm_pipeline_id']"}})],1):_vm._e(),(Object.hasOwn(_vm.element, 'amocrm_status_id'))?_c('div',{staticClass:"zem-collapse-table__row"},[_c('div',{staticClass:"zem-collapse-table__column title"},[_vm._v("ID этапа AmoCRM")]),_c('zem-input',{staticClass:"mt-0",attrs:{"disabled":_vm.$route.query.type === 'pipeline'
                ? !_vm.$can('edit', 'order-pipeline-update')
                : !_vm.$can('edit', 'order-status-update')},on:{"input":function($event){_vm.changeSettings = true}},model:{value:(_vm.element['amocrm_status_id']),callback:function ($$v) {_vm.$set(_vm.element, 'amocrm_status_id', $$v)},expression:"element['amocrm_status_id']"}})],1):_vm._e(),_vm._l((Object.keys(_vm.element.params)),function(el,index){return _c('div',{key:index,staticClass:"zem-collapse-table__row"},[_c('div',{staticClass:"zem-collapse-table__column title"},[_vm._v(_vm._s(_vm.$t(el)))]),_c('zem-input',{staticClass:"mt-0",attrs:{"disabled":_vm.$route.query.type === 'pipeline'
                ? !_vm.$can('edit', 'order-pipeline-update')
                : !_vm.$can('edit', 'order-status-update')},on:{"input":function($event){_vm.changeSettings = true}},model:{value:(_vm.element.params[el]),callback:function ($$v) {_vm.$set(_vm.element.params, el, $$v)},expression:"element.params[el]"}})],1)}),_vm._l((_vm.element['params_schema']),function(el,index){return [(!Object.keys(_vm.element.params).includes(el.key))?_c('div',{key:index,staticClass:"zem-collapse-table__row"},[_c('div',{staticClass:"zem-collapse-table__column title"},[_vm._v(" "+_vm._s(el["title"])+" ")]),_c('zem-input',{staticClass:"mt-0",attrs:{"disabled":_vm.$route.query.type === 'pipeline'
                  ? !_vm.$can('edit', 'order-pipeline-update')
                  : !_vm.$can('edit', 'order-status-update')},on:{"input":function($event){_vm.changeSettings = true}},model:{value:(el.value),callback:function ($$v) {_vm.$set(el, "value", $$v)},expression:"el.value"}})],1):_vm._e()]}),('show_in_graph' in _vm.element)?_c('div',{staticClass:"zem-collapse-table__row"},[_c('div',{staticClass:"zem-collapse-table__column title"},[_vm._v(" Отображать в графике ")]),_c('zem-checkbox',{staticClass:"mt-0",attrs:{"disabled":_vm.$route.query.type === 'pipeline'
                ? !_vm.$can('edit', 'order-pipeline-update')
                : !_vm.$can('edit', 'order-status-update')},on:{"input":function($event){_vm.changeSettings = true}},model:{value:(_vm.element['show_in_graph']),callback:function ($$v) {_vm.$set(_vm.element, 'show_in_graph', $$v)},expression:"element['show_in_graph']"}})],1):_vm._e()],2)])],1):_vm._e(),_c('preloader-wrapper',{attrs:{"show":_vm.$store.state.other.isLoadingItem}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }