<template>
  <div class="tw-w-full tw-h-[calc(100%-45px)] md:tw-h-full">
    <div
      v-if="element"
      class="sidebar-right-container position-relative tw-overflow-y-auto tw-h-full tw-w-full"
    >
      <SidebarRightHeader
        v-if="selectedItems.length < 2"
        :id="element.real_id"
        :creating="newElement"
        :editing="editElement"
        :element="element"
        :element-data="elementData"
        :hash-id="element.id"
        :is-show-edit-button="$can('edit', 'directory-item-update')"
        :loading="createItemLoading"
        :title="element.title"
        @close="closeRightSidebar"
        @create="$emit('on-create')"
        @update="$emit('on-update', element)"
        @edit-element="$emit('on-edit')"
        @on-show-events-list="$emit('on-show-events-list')"
      ></SidebarRightHeader>

      <div class="zem-collapse-table rows mt-3 mb-3">
        <div
          class="zem-collapse-table__row"
          style="grid-template-columns: 1fr 2fr;"
        >
          <div class="zem-collapse-table__column title mt-1">Название</div>
          <ZemInput
            :value="element['title']"
            class="mt-0"
            type="text"
            @input="(e) => (element['title'] = e)"
          />

          <div class="zem-collapse-table__column title mt-1">Статус</div>
          <ZemDropdownList
            v-model="element['status']"
            :options="orderStatuses"
            class="mt-0"
          />

          <div class="zem-collapse-table__column title mt-1">Ответственный</div>
          <ZemDropdownList
            v-model="element['responsibleBy']"
            :options="users ? users : []"
            class="mt-0"
          />

          <div class="zem-collapse-table__column title mt-1">Бюджет</div>
          <ZemInput
            :disabled="
              !element['budgetEdit'] ||
              $can('edit', 'order.update.prohibitions.budget')
            "
            :value="element['budgetEdit']"
            class="mt-0"
            type="number"
            :placeholder="element['budgetEdit'] ? '' : '*****'"
            @input="(e) => (element['budgetEdit'] = e)"
          />

          <div class="zem-collapse-table__column title mt-1">Объект</div>
          <ZemDropdownList
            v-model="element['object']"
            :loading="isLoadingObjects"
            :options="objectsList"
            class="mt-0"
            search
            @on-search="getObjectsList($event)"
          />

          <template v-for="(schema, index) in element.params_schema">
            <template v-if="schema.key === 'disk_url'">
              <div :key="index" class="tw-text-[#9ba6b2] tw-text-xs">
                {{ schema.title }}
              </div>
              <ZemInput
                :disabled="
                  schema.readonly ||
                  $can('edit', 'order.update.prohibitions.disk_url')
                "
                :value="schema.value"
                class="mt-0"
                type="text"
                :placeholder="!schema.readonly ? '' : '*****'"
                @input="(e) => (element.params[schema.key] = e)"
              />
            </template>
          </template>
        </div>
      </div>

      <zem-collapse is-opened-default title="Заказчики">
        <div v-if="customers.length" class="zem-collapse-table rows">
          <div
            v-for="(item, index) in customers"
            :key="index"
            class="zem-collapse-table__row tw-grid-cols-[1fr_1fr_auto] tw-gap-x-2"
          >
            <ZemDropdownList
              v-model="item['human_position']"
              :options="humanPositions"
              class="mt-0"
              placeholder="Должность"
              :disabled="$can('edit', 'order.update.prohibitions.participants')"
            />
            <ZemDropdownList
              v-model="item['human']"
              :loading="item.isLoading"
              :options="customersList"
              :disabled="$can('edit', 'order.update.prohibitions.participants')"
              class="mt-0"
              placeholder="ФИО"
              search
              @on-search="getPeoples(index, $event, 1)"
            />
            <div
              class="zem-collapse-table__row cursor-pointer tw-w-[16px] tw-flex tw-text-xs"
            >
              <zem-dropdown-menu>
                <template v-slot:options>
                  <RouterLink
                    :to="`/counterparties/peoples/${item['human'].value}?redirect=orders-id&redirect_id=${element.id}`"
                    class="tw-block tw-text-nowrap tw-px-3 tw-py-1 hover:tw-bg-[#0DB2B2]/20 tw-text-[#3E3E3E]"
                    target="_blank"
                  >
                    Перейти в карточку
                  </RouterLink>
                  <p
                    v-if="
                      !$can('edit', 'order.update.prohibitions.participants')
                    "
                    class="tw-text-nowrap tw-px-3 tw-py-1 hover:tw-bg-[#0DB2B2]/20 tw-text-[#3E3E3E]"
                    @click="customers.splice(index, 1)"
                  >
                    Удалить заказчика
                  </p>
                </template>
              </zem-dropdown-menu>
            </div>
          </div>
        </div>
        <p v-else class="tw-text-[#9ba6b2] tw-text-xs">Нет значений</p>
        <button
          v-if="!$can('edit', 'order.update.prohibitions.participants')"
          class="tw-flex tw-items-center tw-text-xs tw-mt-2 text-primary tw-font-semibold"
          @click="addCustomer"
        >
          <PlusIcon class="tw-mr-1 tw-font-bold" size="14" stroke-width="3" />
          Заказчик
        </button>
      </zem-collapse>

      <zem-collapse is-opened-default title="Исполнители">
        <div v-if="performers.length" class="zem-collapse-table rows">
          <div
            v-for="(item, index) in performers"
            :key="index"
            class="zem-collapse-table__row tw-grid-cols-[1fr_1fr_auto] tw-gap-x-2"
          >
            <ZemDropdownList
              v-model="item['human_position']"
              :options="humanPositions"
              class="mt-0"
              placeholder="Должность"
              :disabled="$can('edit', 'order.update.prohibitions.participants')"
            />
            <ZemDropdownList
              v-model="item['human']"
              :loading="item.isLoading"
              :options="performersList"
              :disabled="$can('edit', 'order.update.prohibitions.participants')"
              class="mt-0"
              placeholder="ФИО"
              search
              @on-search="getPeoples(index, $event, 0)"
            />
            <div
              class="zem-collapse-table__row cursor-pointer tw-w-[16px] tw-flex tw-text-xs"
            >
              <zem-dropdown-menu>
                <template v-slot:options>
                  <RouterLink
                    :to="`/counterparties/peoples/${item['human'].value}?redirect=orders-id&redirect_id=${element.id}`"
                    class="tw-block tw-text-nowrap tw-px-3 tw-py-1 hover:tw-bg-[#0DB2B2]/20 tw-text-[#3E3E3E]"
                    target="_blank"
                  >
                    Перейти в карточку
                  </RouterLink>
                  <p
                    v-if="
                      !$can('edit', 'order.update.prohibitions.participants')
                    "
                    class="tw-text-nowrap tw-px-3 tw-py-1 hover:tw-bg-[#0DB2B2]/20 tw-text-[#3E3E3E]"
                    @click="performers.splice(index, 1)"
                  >
                    Удалить исполнителя
                  </p>
                </template>
              </zem-dropdown-menu>
            </div>
          </div>
        </div>
        <p v-else class="tw-text-[#9ba6b2] tw-text-xs">Нет значений</p>
        <button
          v-if="!$can('edit', 'order.update.prohibitions.participants')"
          class="tw-flex tw-items-center tw-text-xs tw-mt-2 text-primary tw-font-semibold"
          @click="
            performers.push(
              Object.assign(
                { community_status: { title: '', value: performerID } },
                defaultPerson
              )
            )
          "
        >
          <PlusIcon class="tw-mr-1 tw-font-bold" size="14" stroke-width="3" />
          Исполнитель
        </button>
      </zem-collapse>

      <zem-collapse v-if="object" is-opened-default title="Объект">
        <OrderObjectBlock :element="object" />
      </zem-collapse>

      <zem-collapse is-opened-default title="Мероприятия">
        <OrderEventsBlock
          :order="element"
          :elements="element.events ? element.events.data : []"
          :is-creating="newElement"
          :is-editing="editElement"
        />
      </zem-collapse>

      <zem-collapse is-opened-default title="Сметы">
        <OrderEstimatesBlock />
      </zem-collapse>
    </div>

    <preloader-wrapper :show="$store.state.other.isLoadingItem" />
  </div>
</template>

<script>
import ZemCard from "@/components/ui/ZemCard";
import { logger } from "@/assets/scripts/scripts";
import { options } from "@/mixins/options";
import SidebarRightHeader from "@/components/SidebarRightHeader.vue";
import PreloaderWrapper from "@/components/PreloaderWrapper.vue";
import ZemCollapse from "@/components/ui/ZemCollapse.vue";
import { PlusIcon } from "vue-feather-icons";
import OrderEventsBlock from "@/components/Orders/OrderEventsBlock.vue";
import OrderEstimatesBlock from "@/components/Orders/OrderEstimatesBlock.vue";
import OrdersService from "@/services/orders.service";
import ZemDropdownList from "@/components/ui/ZemDropdownList.vue";
import ZemInput from "@/components/ui/ZemInput.vue";
import ObjectsService from "@/services/objects.service";
import OrderPersonsBlock from "@/components/Orders/OrderPersonsBlock.vue";
import OrderObjectBlock from "@/components/Orders/OrderObjectBlock.vue";
import dayjs from "dayjs";
import ZemLink from "@/components/ui/ZemLink.vue";
import ZemButton from "@/components/ui/ZemButton.vue";
import ZemCardTitle from "@/components/ui/ZemCardTitle.vue";
import OtherService from "@/services/other.service";
import EventsService from "@/services/events.service";
import ZemDropdownMenu from "@/components/ui/ZemDropdownMenu.vue";
import UsersService from "@/services/users.service";

export default {
  emits: ["on-update", "on-edit", "on-close", "on-show-events-list"],
  mixins: [options],
  props: {
    defaultRole: {
      type: String,
      default: null,
    },
  },
  components: {
    ZemDropdownMenu,
    ZemCardTitle,
    ZemButton,
    ZemLink,
    OrderObjectBlock,
    ZemInput,
    ZemDropdownList,
    OrderEstimatesBlock,
    OrderEventsBlock,
    ZemCollapse,
    OrderPersonsBlock,
    PreloaderWrapper,
    SidebarRightHeader,
    ZemCard,
    PlusIcon,
  },
  data() {
    return {
      editElement: true,
      newElement: false,
      elementData: null,
      title: "",
      id: "",
      element: null,
      object: null,
      events: [
        {
          id: 0,
          title: "Дата создания",
          data: "13.09.2023",
        },
        {
          id: 1,
          title: "Дата завершения",
          data: "25.09.2024",
        },
      ],
      currentElementId: null,
      createItemLoading: false,
      customers: [],
      performers: [],
      persons: [],
      customersList: [],
      performersList: [],
      customerID: null,
      performerID: null,
      defaultPerson: {
        human_position: {
          title: null,
          value: null,
        },
        human: {
          title: null,
          value: null,
        },
        isLoading: false,
      },
      schemaData: [],
      orderStatuses: [],
      communityStatuses: [],
      humanPositions: [],
      objectsList: [],
      isLoadingPeoples: false,
      isLoadingObjects: false,
    };
  },
  computed: {
    selectedItems() {
      return this.$store.state.orders.selectedItemsForDelete;
    },
    users() {
      return this.$store.state.users.users.map((user) => ({
        title: user.name,
        value: user.id,
      }));
    },
  },
  mounted() {
    OrdersService.getOrderStatuses().then((r) => {
      this.orderStatuses = r.data.data.map((status) => ({
        title: `${status.pipeline ? status.pipeline.data.name + " - " : ""}${
          status.name
        }`,
        value: status.id,
      }));
    });
    OtherService.getCommunityStatuses().then((r) => {
      this.communityStatuses = r.data.data;
      this.customerID = r.data.data.find((el) => el.title === "Заказчик").value;
      this.performerID = r.data.data.find(
        (el) => el.title === "Исполнитель"
      ).value;
    });
    OtherService.getHumanPositions().then((r) => {
      this.humanPositions = r.data.data;
    });
    UsersService.getAllUsers();
  },
  methods: {
    logger,
    getPeoples(i, search, type) {
      this.customersList = [];
      this.performersList = [];
      if (type) this.customers[i].isLoading = true;
      else this.performers[i].isLoading = true;
      EventsService.getPeoplesList(search).then((r) => {
        if (type) {
          this.customersList = r
            .filter((el) => !el.user_id)
            .map((data) => ({
              title: data.fio,
              value: data.id,
            }));
          this.customers[i].isLoading = false;
        } else {
          this.performersList = r
            .filter((el) => el.user_id)
            .map((data) => ({
              title: data.fio,
              value: data.id,
            }));
          this.performers[i].isLoading = false;
        }
      });
    },
    getObjectsList(search) {
      this.isLoadingObjects = true;
      ObjectsService.getObjectsList(search)
        .then((r) => {
          this.objectsList = r.data;
        })
        .finally(() => {
          this.isLoadingObjects = false;
        });
    },
    getOrder() {
      this.$store.commit("other/onChangeLoadingItem", true);
      const { id } = this.$route.params;
      const defaultValue = {
        title: null,
        value: null,
      };
      try {
        OrdersService.getOrder(id)
          .then((r) => {
            const {
              responsible_by,
              status,
              object,
              object_id,
              budget,
              pipeline,
              events,
            } = r.data.data;

            this.element = {
              ...r.data.data,
              responsibleBy: responsible_by
                ? {
                    title: responsible_by.data.name,
                    value: responsible_by.data.id,
                  }
                : defaultValue,
              status: status
                ? {
                    title: `${pipeline.data.name} - ${status.data.name}`,
                    value: status.data.id,
                  }
                : defaultValue,
              object: object
                ? {
                    title: object.data.name,
                    value: object.data.id,
                  }
                : defaultValue,
              objectValue: object,
              ...(budget ? { budgetEdit: budget.currency.value } : {}),
            };

            if (object_id) this.object = object.data;
            else this.object = null;

            const participants = r.data.data.participants.data;
            let titlesParticipants = [];
            let resultParticipants = [];
            let counter = 0;

            for (let participant of participants) {
              if (
                !titlesParticipants.includes(
                  participant.community_status.data.title
                )
              ) {
                titlesParticipants.push(
                  participant.community_status.data.title
                );
                resultParticipants.push({
                  title: participant.community_status.data.title,
                  id: participant.human.data.id,
                  value: [
                    {
                      human_position: participant.human_position.data.title,
                      human: participant.human.data.fio,
                    },
                  ],
                });
              } else {
                const i = resultParticipants.findIndex(
                  (el) => participant.community_status.data.title === el.title
                );
                resultParticipants[i].value.push({
                  human_position: participant.human_position.data.title,
                  human: participant.human.data.fio,
                });
              }
              counter++;
            }

            events.data.forEach((event) => {
              if (!event.deadlines.data.length) return;
              const { deadlines } = event;
              const datesStart = deadlines.data.map((e) => e.start_at);
              const datesEnd = deadlines.data.map((e) => e.finish_at);
              const earliestDate = datesStart
                .map((e) => e.start_at)
                .reduce((earliest, current) => {
                  return current < earliest ? current : earliest;
                }, datesStart[0]);
              const endDate = deadlines.data
                .map((e) => e.finish_at)
                .reduce((latest, current) => {
                  return current > latest ? current : latest;
                }, datesEnd[0]);
              event.start_at = earliestDate ? dayjs(earliestDate).unix() : null;
              event.finish_at = endDate ? dayjs(endDate).unix() : null;
            });

            this.persons = resultParticipants;
            this.$store.commit("other/onChangeLoadingItem", false);

            const handlerHuman = (data) => {
              return {
                community_status: {
                  title: data.community_status.data.title,
                  value: data.community_status.data.id,
                },
                human_position: {
                  title: data.human_position.data.title,
                  value: data.human_position.data.id,
                },
                human: {
                  title: data.human.data.fio,
                  value: data.human.data.id,
                },
                isLoading: false,
              };
            };

            this.customers = participants
              .filter((el) => el.community_status.data.title === "Заказчик")
              .map((el) => handlerHuman(el));

            this.performers = participants
              .filter((el) => el.community_status.data.title === "Исполнитель")
              .map((el) => handlerHuman(el));
          })
          .catch((e) => {
            if (e.response.status === 422 && this.$route.name !== "orders")
              this.$router.push({ name: "orders" });
            this.closeRightSidebar();
          });
      } catch (e) {
        // console.log(e)
      }
    },
    addCustomer() {
      this.customers.push(
        Object.assign(
          { community_status: { title: "", value: this.customerID } },
          {
            ...this.defaultPerson,
            human_position: this.humanPositions.find(
              (el) => el.value === this.defaultRole
            ),
          }
        )
      );
    },
    closeRightSidebar() {
      this.editElement = false;
      this.newElement = false;
      this.$router.push({ name: "orders" });
      this.$emit("on-close");
      this.$store.commit("sidebars/changeRightSidebar", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-right-container {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  -moz-flex-direction: column;
  overflow-y: auto;

  .zem-dropdown {
    margin-top: 0;
  }
}

.colored {
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: $color-accent;

  img {
    margin-right: 3px;
  }

  &--link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 1024px) {
  .sidebar-right-container {
    width: 100%;
    height: calc(100% - 45px);
    overflow: auto;
  }
}
</style>
